<template>
  <v-container class="full-height">
    <v-col cols="12" class="mentor-details full-height">
      <v-row no-gutters class="full-height">
        <v-col cols="12" md="3" order="1" class="pa-1 mentor-card">
          <v-layout justify-center class="mentor-img">
            <v-img
              lazy
              :src="require('@/assets/' + mentor.avatar)"
              class="rounded-lg"
              alt="mentor avatar"
            ></v-img>
          </v-layout>
          <v-row class="mentor-card__descriptions">
            <v-col cols="2"  style="padding-right: 0">
              <div class="btn-edit">
                <v-icon
                  color="white"
                  size="15"
                >mdi-pencil</v-icon>
              </div>
            </v-col>
            <v-col cols="10" style="padding-left: 0">
              <div class="mentor-img-name mt-3 text-left">
                <span class="span-name">Name:</span>
                <span class="span-name-value">{{ mentor.name }}</span>
              </div>
              <div
                class="mentor-img-position mb-1 text-left"
              >
                <span class="span-name">Location:</span>
                <span class="span-location-value">{{ mentor.localtion }}</span>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="9"
          order="2"
          class="pt-2 pt-md-6 mentor-descriptions"
        >
          <v-row no-gutters>
            <v-col cols="12">
              <p class="mentor-position">{{ mentor.position }}</p>
            </v-col>
            <v-col cols="12">
              <p class="mentor-name">
                {{ mentor.name }}
              </p>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <div
                class="mt-5 mb-3"
                style="font-size: 1.3rem; font-weight: bold"
              >
                Description
              </div>
              <p class="mentor-about">
                {{ mentor.about }}
              </p>
              <ul class="mentor-experience">
                <li v-for="(experience, key) in mentor.experiences" :key="key">
                  {{ experience }}
                </li>
              </ul>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12">
              <div
                class="mt-5 mb-3"
                style="font-size: 1.3rem; font-weight: bold"
              >
                Certificate
              </div>
              <ul class="active_certifications">
                <li v-for="(active_certifications, key) in mentor.active_certifications" :key="key">
                  {{ active_certifications }}
                </li>
              </ul>
            </v-col>
            <v-col
              cols="6"
              md="3"
              class="pa-1"
              v-for="(certificate, key) in mentor.certificates"
              :key="key"
            >
              <!-- Show certificate files on desktop -->
              <v-tooltip
                top
                color="transparent"
                transition="scroll-y-transition"
                :open-on-focus="false"
                :open-on-click="false"
                class="hidden-sm-and-down"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-img
                    lazy
                    contain
                    :src="require('@/assets/' + certificate.image)"
                    class="img-cer desktop-view"
                    v-bind="attrs"
                    v-on="on"
                    alt="cer"
                  ></v-img>
                </template>
                <span class="tooltip-box"
                  ><v-img
                    lazy
                    contain
                    :src="require('@/assets/' + certificate.image)"
                    class="tooltip-img"
                    alt="cer box"
                  ></v-img
                ></span>
              </v-tooltip>
              <!-- Show certificate files on mobile -->
              <v-img
                lazy
                contain
                :src="require('@/assets/' + certificate.image)"
                class="img-cer mobile-view"
                alt="cer mobile"
              ></v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import { mentors } from "@/commons/Mentor";
export default {
  name: "MentorDetailsComponent",
  data() {
    return {
      mentor: {},
    };
  },
  created: function () {
    this.getMentorById(this.$route.params.id);
  },
  methods: {
    getMentorById(mentorID) {
      const mentorById = mentors.find((mentor) => mentor.id === mentorID);

      if (mentorById) {
        this.mentor = mentorById;
      }
    },
  },
};
</script>

<stype lang="scss" scoped>
@import "@/scss/common";

.mentor-details {
  padding: 0;

  .mentor-card {
    padding: 0 !important;

    &__descriptions {
      background-color: #24395a;
      margin: 0;

      .btn-edit {
        display: flex;
      }
    }
  }

  .mentor-descriptions {
    padding: 64px !important;
    background-color: #f4eeee;

    @include mobile {
      padding: 24px 16px !important;
    }
  }
  .mentor-position {
    font-style: italic;
    font-weight: 100;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin: 0 !important;
  }

  .mentor-name {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    color: #ec7211;
    margin: 0 !important;

    @include mobile {
      font-size: 32px;
      line-height: 39px;
    }
  }

  .mentor-about {
    font-style: italic;
    color: $grey-1;
  }

  .mentor-experience,
  .active_certifications
  {
    color: $grey-1;
    li {
      margin-bottom: 8px;
    }
  }

  .mentor-img-name,
  .mentor-img-position {
    display: flex;
    column-gap: 4px;

    .span-name {
      color: #737373;
    }

    .span-name-value {
      display: block;
      width: 65%;
      text-align: center;
    }

    .span-location-value {
      display: block;
      width: 50%;
      text-align: center;
    }

    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #ffffff;
    letter-spacing: 0.15px;
  }

  .mentor-img-name {
    margin-bottom: 10px;
    margin-top: 0 !important;
  }

  .desktop-view {
    display: block;

    @include mobile {
      display: none;
    }
  }

  .mobile-view {
    display: none;

    @include mobile {
      display: block;
    }
  }
}

.img-cer {
  cursor: pointer;
}

.tooltip-box {
  .tooltip-img {
    width: 500px;
    max-width: 100%;
  }
}
</stype>
