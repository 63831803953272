var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: _vm.visibilityChanged,
    throttle: 300,
    throttleOptions: {
      leading: 'visible',
    },
  }),expression:"{\n    callback: visibilityChanged,\n    throttle: 300,\n    throttleOptions: {\n      leading: 'visible',\n    },\n  }"}],class:_vm.animations},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h4',{staticClass:"block-title"},[_vm._v("Hình thức học")])]),_vm._l((_vm.items),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"d-flex justify-center",attrs:{"tile":"","elevation":"0"}},[_c('v-card',{attrs:{"tile":"","elevation":"0","max-width":"250"}},[_c('v-layout',{attrs:{"justify-center":""}},[_c('v-img',{attrs:{"max-width":"60","src":require('@/assets/' + item.image),"alt":"learning","lazy":""}})],1),_c('div',{staticClass:"text-center my-3 common-text"},[_vm._v(_vm._s(item.content))])],1)],1)],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }