var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: _vm.visibilityChanged,
    throttle: 300,
    throttleOptions: {
      leading: 'visible',
    },
  }),expression:"{\n    callback: visibilityChanged,\n    throttle: 300,\n    throttleOptions: {\n      leading: 'visible',\n    },\n  }"}],class:_vm.animations},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"12","md":"4"}},[_c('h4',{staticClass:"block-title text-left"},[_vm._v(" Chứng chỉ "),_c('span',{staticClass:"orange--text"},[_vm._v("AWS")]),_c('br'),_vm._v("có thực sự khó? ")]),_c('div',{staticClass:"person-info mt-8 mb-3 font-italic"},[_vm._v(" Bản thân mình đã từng thi đến lần thứ 3 mới có thể pass được chứng chỉ AWS Certified Solutions Architect - Associate. Vì vậy, mình hiểu được những khó khăn mà nhiều người gặp phải khi học các khóa Online ")]),_c('div',{staticClass:"mentor-name mt-n2 mb-2"},[_c('div',[_vm._v("-")]),_vm._v(" Mentor Phong Nguyen ")])]),_c('v-col',{staticClass:"pl-2 pt-5",attrs:{"cols":"12","md":"8"}},[_c('v-row',_vm._l((_vm.items),function(item,key){return _c('v-col',{key:key,class:{ 'py-0': _vm.$vuetify.breakpoint.xs },attrs:{"cols":"12","md":"6"}},_vm._l((item),function(lists,key2){return _c('div',{key:key2,staticClass:"intro-item mb-8"},[_c('div',{staticClass:"intro-item-content"},[_c('ul',_vm._l((lists),function(list,key3){return _c('li',{key:key3},[_vm._v(_vm._s(list))])}),0)]),_c('div',{staticClass:"intro-item-bg"}),_c('div',{staticStyle:{"padding":"15px 5px"}},[_c('ul',_vm._l((lists),function(list,key3){return _c('li',{key:key3},[_vm._v(_vm._s(list))])}),0)])])}),0)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }