var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: _vm.visibilityChanged,
    throttle: 300,
    throttleOptions: {
        leading: 'visible',
    },
}),expression:"{\n    callback: visibilityChanged,\n    throttle: 300,\n    throttleOptions: {\n        leading: 'visible',\n    },\n}"}],class:_vm.animations},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h4',{staticClass:"block-title"},[_vm._v("Khóa học")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"list-course"},_vm._l((_vm.items),function(item,index){return _c('div',{key:index},[_c('router-link',{staticClass:"course-link",attrs:{"to":{ name: item.link }}},[_c('v-card',{staticClass:"course-item"},[_c('v-img',{attrs:{"src":require('@/assets/' + item.image),"lazy":"","height":"195","aspect-ratio":"4/3","cover":""}}),_c('v-card-title',{staticClass:"course-name",domProps:{"innerHTML":_vm._s(item.name)}}),_c('v-card-subtitle',{staticClass:"course-descriptions"},[_vm._v(_vm._s(item.descriptions))]),_c('div',{staticClass:"course-more-info"},[_c('div',_vm._l((item.mentors),function(mentor,indexMentors){return _c('router-link',{key:indexMentors,staticClass:"mentor-link",attrs:{"to":'/mentors/' + mentor.id}},[_c('v-avatar',{attrs:{"size":"31"}},[_c('v-img',{attrs:{"alt":mentor.name,"src":require('@/assets/' + mentor.thumbnail),"lazy":"","cover":"","height":"31","width":"31"}})],1)],1)}),1),_c('div',[_c('span',{staticClass:"course-price"},[_vm._v(_vm._s(item.price))])])])],1)],1)],1)}),0)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }