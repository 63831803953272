var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: _vm.visibilityChanged,
    throttle: 300,
    throttleOptions: {
      leading: 'visible',
    },
  }),expression:"{\n    callback: visibilityChanged,\n    throttle: 300,\n    throttleOptions: {\n      leading: 'visible',\n    },\n  }"}],staticClass:"contact-banner",class:[
    {
      'contact-height-sx': _vm.$vuetify.breakpoint.xs,
      'contact-height-sm': _vm.$vuetify.breakpoint.smAndUp,
    },
    _vm.animations,
  ],attrs:{"fluid":"","fill-height":""}},[_c('v-overlay',{staticStyle:{"z-index":"1"},attrs:{"absolute":"","color":"#02244b","opacity":"0.85"}},[_c('v-row',{staticClass:"text-center mt-3",class:{
        'contact-width-large': _vm.$vuetify.breakpoint.mdAndUp,
      },attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"block-title",class:{
            'mb-6': _vm.$vuetify.breakpoint.xs,
            'mb-10': _vm.$vuetify.breakpoint.smAndUp,
          }},[_vm._v(" Liên hệ với chúng tôi ")])]),_c('v-col',{staticClass:"mb-5",attrs:{"cols":"12","sm":"4"}},[_c('div',{staticClass:"contact-block-title",class:{
            'mb-3': _vm.$vuetify.breakpoint.smAndUp,
          }},[_vm._v(" Skype ")]),_c('div',[_c('a',{staticClass:"white--text text-decoration-none",attrs:{"href":"https://join.skype.com/invite/rJRgjxbVy5WS","target":"_blank","aria-label":"Join Skype With Us"}},[_vm._v("phong.xuan1")])])]),_c('v-col',{staticClass:"mb-5",attrs:{"cols":"12","sm":"4"}},[_c('div',{staticClass:"contact-block-title",class:{
            'mb-3': _vm.$vuetify.breakpoint.smAndUp,
          }},[_vm._v(" Email ")]),_c('div',[_c('a',{staticClass:"white--text text-decoration-none",attrs:{"href":"mailto:admin@cloudmentor.pro","target":"_blank","aria-label":"Join Gmail With Us"}},[_vm._v("admin@cloudmentor.pro")])])]),_c('v-col',{staticClass:"mb-5",attrs:{"cols":"12","sm":"4"}},[_c('div',{staticClass:"contact-block-title",class:{
            'mb-3': _vm.$vuetify.breakpoint.smAndUp,
          }},[_vm._v(" Social ")]),_c('div',_vm._l((_vm.icons),function(icon,key){return _c('v-btn',{key:key,staticClass:"white--text",attrs:{"icon":"","link":"","href":icon.link,"target":"_blank","aria-label":"Contact With Social Network"}},[_c('v-icon',{attrs:{"size":"24px","color":"white"}},[_vm._v(_vm._s(icon.icon))])],1)}),1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }