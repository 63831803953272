<template>
    <v-container v-observe-visibility="{
        callback: visibilityChanged,
        throttle: 300,
        throttleOptions: {
            leading: 'visible',
        },
    }" :class="animations">
        <v-row>
            <v-col cols="12">
                <h4 class="block-title">Khóa học</h4>
            </v-col>
            <v-col cols="12">
                <div class="list-course">
                    <div v-for="(item, index) in items" :key="index">
                        <router-link :to="{ name: item.link }" class="course-link">
                            <v-card class="course-item">
                                <v-img :src="require('@/assets/' + item.image)" lazy height="195" aspect-ratio="4/3"
                                    cover></v-img>

                                <v-card-title class="course-name" v-html="item.name"></v-card-title>

                                <v-card-subtitle class="course-descriptions">{{ item.descriptions }}</v-card-subtitle>

                                <div class="course-more-info">
                                    <div>
                                        <router-link :to="'/mentors/' + mentor.id" class="mentor-link"
                                            v-for="(mentor, indexMentors) in item.mentors" :key="indexMentors">
                                            <v-avatar size="31">
                                                <v-img :alt="mentor.name" :src="require('@/assets/' + mentor.thumbnail)"
                                                    lazy cover height="31" width="31"></v-img>
                                            </v-avatar>
                                        </router-link>
                                    </div>

                                    <div>
                                        <span class="course-price">{{ item.price }}</span>
                                    </div>
                                </div>
                            </v-card>
                        </router-link>
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { courses } from '@/commons/Courses';

export default {
    name: "CoursePriceComponent",

    data() {
        return {
            items: courses,
            isVisible: false,
        };
    },
    computed: {
        animations: function () {
            return this.isVisible ? 'block-hidden block-visible' : 'block-hidden';
        }
    },
    methods: {
        visibilityChanged(isVisible) {
            this.isVisible = isVisible
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/scss/common";

.list-course {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 98px;
    flex-wrap: wrap;

    @media (max-width: 1240px) {
        gap: 49px;
    }

    @media (max-width: 1024px) {
        grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 768px) {
        gap: 25px;
    }

    @media (max-width: 600px) {
        grid-template-columns: 1fr;
        gap: 16px;
    }

    .course-link {
        display: block;
        text-decoration: none;
        height: 100%;


        .course-item {
            border: 1px solid transparent;
            border-radius: 10px !important;
            height: 100%;
            position: relative;
            padding-bottom: 70px;
            transition: 0.8ms linear;

            &:hover {
                border: 1px solid $blue-dark;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            }


            .course-name {
                display: block;
                text-align: center;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                color: $blue-dark;
                margin: 16px 16px 0;
                padding: 0;
            }

            .course-descriptions {
                text-align: center;
                font-size: 14px;
                font-style: italic;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 10px;
                margin: 16px 16px 0;
                padding: 0;
                color: $grey-5;
            }

            .course-more-info {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                padding: 0 16px 16px;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;

                .mentor-link {
                    text-decoration: none;
                    margin-right: 7px;
                }

                .course-price {
                    text-align: center;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    color: $blue-dark;
                }
            }
        }
    }
}
</style>
