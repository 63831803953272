export const mentors = [
  {
    id: "nguyen-xuan-phong",
    name: "Nguyễn Xuân Phong",
    position: "Cloud Solution Architect",
    localtion: "Tokyo, Japan",
    thumbnail: "xuan-phong/webp/thumbnail.webp",
    avatar: "xuan-phong/webp/avatar.webp",
    active_certifications: [
      `AWS Certified Solutions Architect - Associate (SAA)`,
      `AWS Certified Developer - Associate (DVA)`,
      `AWS Certified SysOps Administrator - Associate (SOA)`,
      `AWS Certified Solutions Architect - Professional (SAP)`,
      `AWS Certified DevOps Engineer - Professional (DOP)`,
      `AWS Certified Security - Specialty (SCS)`,
      `AWS Certified Database - Specialty (DBS)`
    ],
    certificates: [
      {
        image: "xuan-phong/webp/SAA.webp",
      },
      {
        image: "xuan-phong/webp/DVA.webp",
      },
      {
        image: "xuan-phong/webp/SOA.webp",
      },
      {
        image: "xuan-phong/webp/SAP.webp",
      },
      {
        image: "xuan-phong/webp/DOP.webp",
      },
      {
        image: "xuan-phong/webp/SCS.webp",
      },
      {
        image: "xuan-phong/webp/DBS.webp",
      },
    ],
    about: `Với gần 10 năm kinh nghiệm làm việc cho các khách hàng lớn của
    Nhật, trải qua nhiều vị trí khác nhau: Web Application
    Developer, DevOps Engineer, Bridge System Engineer, Cloud
    Engineer.`,
    experiences: [
      `Có kinh nghiệm thiết kế, xây dựng, vận hành hệ thống trên
      AWS, đặc biệt là Cloud Native.`,
      `Có kinh nghiệm luyện thi nhiều khóa AWS nội bộ và bên ngoài
      đạt tỷ lệ pass 90% lần đầu.`,
      `Mentor có đam mê và nhiệt huyết với công việc giảng dạy`,
    ],
  },
  {
    id: "bui-tuan-minh",
    name: "Bùi Tuấn Minh",
    position: "Cloud Engineer",
    localtion: "Tokyo, Japan",
    thumbnail: "tuan-minh/webp/thumbnail.webp",
    avatar: "tuan-minh/webp/avatar.webp",
    active_certifications: [
      `AWS Certified Solutions Architect - Associate (SAA)`,
      `AWS Certified Developer - Associate (DVA)`,
      `AWS Certified SysOps Administrator - Associate (SOA)`,
      `AWS Certified Data Engineer - Associate (DEA)`,
      `AWS Certified Solutions Architect - Professional (SAP)`,
      `AWS Certified DevOps Engineer - Professional (DOP)`,
      `AWS Certified Data Analytics - Specialty (DAS)`,
      `AWS Certified Security - Specialty (SCS)`,
      `AWS Certified Database - Specialty (DBS)`,
      `AWS Certified Advanced Networking - Specialty (ANS)`
    ],
    certificates: [
      {
        image: "tuan-minh/webp/SAA.webp",
      },
      {
        image: "tuan-minh/webp/DVA.webp",
      },
      {
        image: "tuan-minh/webp/SOA.webp",
      },
      {
        image: "tuan-minh/webp/DEA.webp",
      },
      {
        image: "tuan-minh/webp/SAP.webp",
      },
      {
        image: "tuan-minh/webp/DOP.webp",
      },
      {
        image: "tuan-minh/webp/DAS.webp",
      },
      {
        image: "tuan-minh/webp/SCS.webp",
      },
      {
        image: "tuan-minh/webp/DBS.webp",
      },
      {
        image: "tuan-minh/webp/ANS.webp",
      },
    ],
    about: "Với hơn 3 năm kinh nghiệm làm việc cho khách hàng lớn của Nhật, trải qua các công việc như: Thiết kế, làm PoC thử nghiệm kiến trúc, xây dựng và kiểm thử hệ thống. Hiện tại đang làm việc như 1 member tại công ty Khách hàng là công ty chuyên tư vấn giải pháp về AWS Cloud.",
    experiences: [
      `Có kinh nghiệm thực tế nhiều dự án với AWS, đặc biệt là serverless technology`,
      `Kinh nghiệm training nhiều khóa AWS nội bộ và bên ngoài`,
      `Nhiệt tình hỗ trợ học viên trong và ngoài lớp học`,
    ],
  },
];
