var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: _vm.visibilityChanged,
    throttle: 300,
    throttleOptions: {
      leading: 'visible',
    },
  }),expression:"{\n    callback: visibilityChanged,\n    throttle: 300,\n    throttleOptions: {\n      leading: 'visible',\n    },\n  }"}],class:_vm.animations},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h4',{staticClass:"block-title"},[_vm._v("Mục tiêu khóa học")])]),_c('v-col',{attrs:{"cols":"12"}},_vm._l((_vm.items),function(item,index){return _c('v-card',{key:index,staticClass:"pa-4 mb-3 text-center rounded",class:_vm.getClassMargin(index),attrs:{"tile":"","elevation":"0","color":_vm.getColor(index),"dark":""}},[_vm._v(_vm._s(item))])}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }