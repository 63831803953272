var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: _vm.visibilityChanged,
    throttle: 300,
    throttleOptions: {
      leading: 'visible',
    },
  }),expression:"{\n    callback: visibilityChanged,\n    throttle: 300,\n    throttleOptions: {\n      leading: 'visible',\n    },\n  }"}],class:_vm.animations},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h4',{staticClass:"block-title"},[_vm._v("Vì sao nên chọn khóa học này?")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{class:{ 'pr-2': _vm.$vuetify.breakpoint.smAndUp },attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"list-reasons px-5 fill-height"},_vm._l((_vm.reasons.left),function(reason,key){return _c('div',{key:key,staticClass:"reason-item d-flex align-end",class:{
                md: _vm.$vuetify.breakpoint.mdAndUp,
              }},[_c('p',[_vm._v(_vm._s(reason))])])}),0)]),_c('v-col',{class:{ 'pl-2': _vm.$vuetify.breakpoint.smAndUp },attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"list-reasons px-5 fill-height"},_vm._l((_vm.reasons.right),function(reason,key){return _c('div',{key:key,staticClass:"reason-item d-flex align-end",class:{
                md: _vm.$vuetify.breakpoint.mdAndUp,
              }},[_c('p',[_vm._v(_vm._s(reason))])])}),0)])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }