var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: _vm.visibilityChanged,
    throttle: 300,
    throttleOptions: {
      leading: 'visible',
    },
  }),expression:"{\n    callback: visibilityChanged,\n    throttle: 300,\n    throttleOptions: {\n      leading: 'visible',\n    },\n  }"}],class:_vm.animations},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h4',{staticClass:"block-title"},[_vm._v(" Giới thiệu về "),_c('span',{staticClass:"orange--text"},[_vm._v("Mentor")])])])],1),_c('v-row',[_c('v-col',{staticClass:"mentors-avatar",attrs:{"cols":"12"}},[_c('div',{staticClass:"mentor-blocks"},_vm._l((_vm.mentors),function(mentor,key){return _c('div',{key:key},[_c('router-link',{staticClass:"mentor-block",attrs:{"to":'/mentors/' + mentor.id}},[_c('v-avatar',{staticClass:"mentor-avatar",attrs:{"size":"198"}},[_c('v-img',{attrs:{"alt":mentor.name,"src":require('@/assets/' + mentor.thumbnail),"lazy":"","cover":"","height":"198","width":"198"}})],1),_c('p',{staticClass:"mentor-name"},[_vm._v(_vm._s(mentor.name))]),_c('p',{staticClass:"mentor-position"},[_vm._v(_vm._s(mentor.position))])],1)],1)}),0)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }