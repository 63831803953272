var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"home-banner",attrs:{"fluid":""}},[_c('v-overlay',{staticStyle:{"z-index":"1"},attrs:{"absolute":"","color":"#02244b","opacity":"0.85"}},[_c('v-layout',{staticStyle:{"max-width":"850px"},attrs:{"justify-center":"","align-center":"","column":"","pa-5":""}},[_c('v-row',{staticClass:"mb-5",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"d-flex flex-row align-center",attrs:{"flat":"","tile":"","color":"transparent"}},[_c('v-card',{attrs:{"flat":"","tile":"","color":"transparent"}},[_c('div',{staticClass:"font-weight-bold orange--text",class:{
                  'text-h2 mr-2': _vm.$vuetify.breakpoint.xs,
                  'banner-big-text mr-4': _vm.$vuetify.breakpoint.smAndUp,
                }},[_vm._v(" AWS ")])]),_c('v-card',{attrs:{"flat":"","tile":"","color":"transparent"}},[_c('div',{class:{
                  'body-2': _vm.$vuetify.breakpoint.xs,
                  'text-h5': _vm.$vuetify.breakpoint.smAndUp,
                }},[_vm._v(" Chinh phục cùng"),_c('br'),_vm._v("Cloud Mentor Pro ")])])],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"font-weight-bold orange--text pl-5",class:{
              'text-h2': _vm.$vuetify.breakpoint.xs,
              'banner-big-text': _vm.$vuetify.breakpoint.smAndUp,
            }},[_vm._v(" Certificate ")])])],1)],1),_c('v-layout',{attrs:{"justify-center":"","align-center":"","column":"","pa-5":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"d-flex flex-column flex-sm-row align-center",attrs:{"flat":"","tile":"","color":"transparent"}},[_c('v-card',{staticClass:"order-sm-2 ml-sm-12 mb-3 text-center",attrs:{"flat":"","tile":"","color":"transparent"}},[_c('div',{staticClass:"text-h5 mb-2"},[_vm._v("LỊCH KHAI GIẢNG")]),_c('div',{staticClass:"mb-1"},[_vm._v(" AWS Certified Solutions Architect - Associate ")]),_c('div',{staticClass:"orange--text mb-3"},[_c('span',{staticClass:"open-date"},[_vm._v("06-02-2025 (Thu)")])]),_c('div',{staticClass:"mb-1"},[_vm._v("AWS Certified Developer - Associate")]),_c('div',{staticClass:"orange--text"},[_c('span',{staticClass:"open-date"},[_vm._v("05-02-2025 (Wed)")])])]),_c('v-card',{staticClass:"order-sm-1 mr-sm-12",attrs:{"flat":"","tile":"","color":"transparent"}},[_c('a',{staticClass:"btnDangky btn-scale",attrs:{"href":"https://forms.gle/9kC3wdcbU3zRPmVNA","target":"_blank","aria-label":"Đăng ký ngay"}},[_vm._v("Đăng ký ngay")])])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }