var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: _vm.visibilityChanged,
    throttle: 300,
    throttleOptions: {
      leading: 'visible',
    },
  }),expression:"{\n    callback: visibilityChanged,\n    throttle: 300,\n    throttleOptions: {\n      leading: 'visible',\n    },\n  }"}],staticClass:"mb-5",class:_vm.animations},[_c('v-card',{staticClass:"d-flex flex-column flex-sm-row justify-center",attrs:{"flat":"","tile":""}},[_c('v-row',{staticStyle:{"max-width":"800px"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h4',{staticClass:"block-title"},[_c('span',{staticClass:"block-title-faq"},[_vm._v("Câu hỏi thường gặp")])])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.questions),function(question,key){return _c('v-col',{key:key,attrs:{"cols":"12"}},[_c('div',{staticClass:"question-box"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"2","sm":"1"}},[_c('div',{staticClass:"question-box-icon",class:{ actived: question.isActived }},[_c('v-btn',{attrs:{"icon":"","aria-label":"Button Expand"},on:{"click":function($event){question.isActived = !question.isActived}}},[(!question.isActived)?_c('v-icon',{attrs:{"size":"45px"}},[_vm._v("mdi-plus")]):_c('v-icon',{attrs:{"size":"45px"}},[_vm._v("mdi-minus")])],1)],1)]),_c('v-col',{attrs:{"cols":"10","sm":"11"}},[_c('div',{staticClass:"question-box-title",on:{"click":function($event){question.isActived = !question.isActived}}},[_vm._v(" "+_vm._s(question.title)+" ")]),_c('div',{staticClass:"question-box-content",class:{ hide: !question.isActived }},_vm._l((question.content),function(content,index){return _c('v-row',{key:index,attrs:{"no-gutters":""}},[_c('v-col',[_c('v-card',{staticClass:"content-text",attrs:{"flat":"","tile":""},domProps:{"innerHTML":_vm._s(content)}})],1)],1)}),1)])],1)],1)])}),1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }