<template>
  <v-container
    v-observe-visibility="{
      callback: visibilityChanged,
      throttle: 300,
      throttleOptions: {
        leading: 'visible',
      },
    }" 
    :class="animations"
  >
    <v-row>
      <v-col cols="12"><h4 class="block-title">Hình thức học</h4></v-col>
      <v-col cols="12" md="4" v-for="(item, index) in items" :key="index">
        <v-card tile elevation="0" class="d-flex justify-center">
          <v-card tile elevation="0" max-width="250">
            <v-layout justify-center>
              <v-img
                max-width="60"
                :src="require('@/assets/' + item.image)"
                class=""
                alt="learning"
                lazy
              ></v-img>
            </v-layout>
            <div class="text-center my-3 common-text">{{ item.content }}</div>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "LearningFormatComponent",

  data: () => ({
    items: [
      {
        image: "webp/icon-notebook.webp",
        content: "Học ONLINE, tương tác trực tiếp với Mentor qua Google Meet",
      },
      {
        image: "webp/icon-google-meet.webp",
        content: "Có video record gửi đến học viên sau mỗi buổi học",
      },
      {
        image: "webp/icon-discord.webp",
        content:
          "Học viên và Mentor trao đổi ngoài buổi học thông qua Discord",
      },
    ],
    isVisible: false,
  }),
  computed: {
    animations: function() {
      return this.isVisible ? 'block-hidden block-visible' : 'block-hidden';
    }
  },
  methods: {
    visibilityChanged (isVisible) {
      this.isVisible = isVisible
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/common";
</style>
