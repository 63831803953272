import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "@/views/HomeView";
import SaaView from "@/views/SolutionsArchitectAssociate";
import Saa37View from "@/views/SolutionsArchitectAssociate37.vue";
import SapView from "@/views/SolutionsArchitectProfessional";
import DaView from "@/views/DeveloperAssociate";
import SoaView from "@/views/SysOpsAdministratorAssociate.vue";
import goTo from "vuetify/lib/services/goto";
import MentorDetails from "@/views/MentorDetails";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "",
      description: "Discover the world of AWS with our comprehensive learning resources. Whether you're a beginner or an experienced user, our tutorials, guides and courses will help you master AWS services and solutions. Start your journey to the cloud today"
    },
  },
  {
    path: "/luyen-thi-aws-certified-solutions-architect-associate",
    name: "saa",
    component: SaaView,
    meta: {
      title: "Luyện thi AWS Certified Solutions Architect - Associate (SAA-C03)",
      description: "Luyện thi AWS Certified Solutions Architect - Associate (SAA-C03)"
    },
  },
  {
    path: "/luyen-thi-aws-certified-solutions-architect-associate-37",
    name: "saa37",
    component: Saa37View,
    meta: {
      title: "Luyện thi AWS Certified Solutions Architect - Associate (SAA-C03)",
      description: "Luyện thi AWS Certified Solutions Architect - Associate (SAA-C03)"
    },
  },
  {
    path: "/luyen-thi-aws-certified-developer-associate",
    name: "da",
    component: DaView,
    meta: {
      title: "Luyện thi AWS Certified Developer - Associate (DVA-C02)",
      description: "Luyện thi AWS Certified Developer - Associate (DVA-C02)"
    },
  },
  {
    path: "/luyen-thi-aws-certified-solutions-architect-professional",
    name: "sap",
    component: SapView,
    meta: {
      title: "Luyện thi AWS Certified Solutions Architect - Professional (SAP-C02)",
      description: "Luyện thi AWS Certified Solutions Architect - Professional (SAP-C02)",
    },
  },
  {
    path: "/luyen-thi-aws-certified-sysops-associate",
    name: "soa",
    component: SoaView,
    meta: {
      title: "Luyện thi AWS Certified SysOps Administrator - Associate (SOA-C02)",
      description: "Luyện thi AWS Certified SysOps Administrator - Associate (SOA-C02)",
    },
  },
  {
    path: "/mentors/:id",
    name: "mentor_detail",
    component: MentorDetails,
    meta: {
      title: "Mentor",
      description: "Mentor của chúng tôi có nhiều năm kinh nghiệm trong lĩnh vực xây dựng và quản lý hạ tầng aws, đồng thời cũng có nhiều kinh nghiệm giảng dạy các lớp trong và ngoài công ty. Nỗ lực phát triển bản thân, tận tình hỗ trợ học viên là tôn chỉ của chúng tôi."
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  routes,
});

export default router;
